html, body {
  overflow-x: hidden;
}

html {
  --primary: #f48d2c;
  --primary-variant: #bc5f00;
  --secondary: #006e5f;
  --secondary-variant: #004235;
  --primary-text: #dfe0df;
  --secondary-text: #222;
  --primary-accent: #ffbe5d;
  --secondary-accent: #449d8c;
  --font-scale-1: 3.052em;
  --font-scale-2: 2.441em;
  --font-scale-3: 1.953em;
  --font-scale-4: 1.25em;
}

#banner {
  background: var(--primary-accent);
  color: var(--secondary);
  text-align: center;
  padding: 1rem;
}

#menu {
  --menu-page-translate-x: 0;
  border-bottom: solid 3px var(--primary-variant);
}

#carousel {
  --carousel-translate-x: 0;
  transform: translateX(var(--carousel-translate-x));
}

body {
  background: #000 url("https://res.cloudinary.com/codefinity/image/upload/q_auto/f_auto/c_scale,h_960,dpr_auto/v1573748892/romero/blackboard.jpg") center / cover no-repeat;
  font-family: Quicksand, sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.45;
}

h1, h2, h3, h4 {
  line-height: 1.15;
}

h1 {
  clip: rect(1px, 1px, 1px, 1px);
  display: none;
}

h2 {
  font-size: var(--font-scale-1);
}

h2 .fas {
  margin: 0 .5rem;
}

#get-hungry h3 {
  font-size: var(--font-scale-2);
  font-weight: bold;
}

h3:not(#get-hungry h3) {
  font-family: Pacifico, cursive;
  font-size: var(--font-scale-2);
}

h4 {
  font-size: var(--font-scale-3);
}

a, address, button, li, p {
  font-size: var(--font-scale-4);
}

address, button {
  font-weight: 800;
}

strong {
  text-transform: uppercase;
}

button, figcaption {
  padding: .5rem;
}

a, header button {
  color: inherit;
}

body, footer button {
  color: var(--primary-text);
}

header button, footer button {
  margin-bottom: 1rem;
}

.app-icon {
  width: 9.6rem;
}

ul {
  padding-inline-start: 0;
}

address, main {
  text-align: center;
}

address {
  margin: .5rem;
  font-style: normal;
}

a {
  text-decoration: none;
}

address p {
  margin: .25rem;
}

header, footer {
  padding: 1rem;
}

figure, img {
  max-width: 100%;
}

figure {
  background: #00000040;
  margin-block: 0;
  margin-inline: 0;
}

#menu section {
  color: var(--secondary-text);
  padding: .5rem 1rem;
}

.button--cta {
  background: var(--secondary);
}

li {
  list-style-type: none;
}

.button {
  border: none;
  border-radius: 3px;
  max-width: 9.6rem;
  padding: .5rem;
}

.chownow-order-online {
  font-weight: bold;
}

#banner p {
  padding: .25rem 0;
}

#menu p {
  margin-top: 3px;
}

#banner p:first-of-type {
  border-bottom: solid thin var(--secondary-accent);
}

#carousel figure {
  min-width: 100vw;
}

#hours {
  color: var(--primary-text);
  font-size: 1.25em;
}

#get-hungry h2 {
  margin: .5rem 0;
}

#get-hungry section {
  background: var(--secondary);
  margin-bottom: 0;
  padding: .5rem;
}

#get-hungry li:not(:last-child):after {
  content: " •";
  margin: 0 .5rem;
}

#get-hungry a {
  color: var(--primary-accent);
}

#get-hungry li:nth-child(2n) {
  padding-top: .5rem;
}

#menu .menu-items-wrapper, #carousel {
  transition: transform .25s ease-in;
}

#menu .menu-items-wrapper {
  transform: translateX(var(--menu-page-translate-x));
}

#menu div div {
  background: url("https://res.cloudinary.com/codefinity/image/upload/q_auto/f_auto/v1574259805/romero/d7749711e0ad324e6505f8ae0e12edca.jpg") center / cover no-repeat;
  min-width: 100vw;
}

#get-hungry .grid-wrapper div {
  position: relative;
}

#menu footer {
  border-bottom: thin solid #000;
}

.align-items-center {
  align-items: center;
}

.display-inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.flex--column {
  flex-direction: column;
}

.grid-wrapper {
  display: grid;
}

.justify-content-space-between {
  justify-content: space-between;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

@media screen and (prefers-reduced-motion: reduce) {
  #menu .menu-items-wrapper, #carousel {
    transition: none;
  }
}

@media screen and (width >= 667px) {
  header .logo {
    max-width: 50%;
  }

  #get-hungry .grid-wrapper {
    grid-gap: 1rem 3px;
    grid-template-columns: repeat(2, .5fr);
    width: 100vw;
  }

  #get-hungry h2 {
    margin: 1.5rem 0;
  }

  #menu div div {
    min-width: 50vw;
  }

  #get-hungry .grid-wrapper div {
    margin: 0;
  }

  #get-hungry .grid-wrapper figure {
    height: 100%;
  }

  .flex-md {
    display: flex;
  }

  .flex--column-md {
    flex-direction: column;
  }

  .flex--row-md {
    flex-direction: row;
  }

  .justify-content-space-between-md {
    justify-content: space-between;
  }

  .margin-bottom-auto-md {
    margin-bottom: auto;
  }

  .margin-right-auto-md {
    margin-right: auto;
  }

  .is-visible-md {
    display: initial;
  }
}

@media screen and (width >= 1024px) {
  body {
    font-size: 16px;
  }

  header .logo {
    max-width: 25%;
  }

  #get-hungry .grid-wrapper {
    grid-template-columns: repeat(3, .333333fr);
  }
}

@media screen and (width <= 767px) {
  .button[class*="is-visible"] {
    display: none;
  }
}

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0);
  }
}

.bounce-in-down, .swing {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.bounce-in-down {
  animation-name: bounceInDown;
}

.swing {
  transform-origin: top;
  animation-name: swing;
  animation-iteration-count: infinite;
}

.swing:first-of-type {
  animation-delay: 1.25s;
}

.swing:nth-of-type(2) {
  animation-delay: 1.5s;
}

.swing:nth-of-type(3) {
  animation-delay: 1.75s;
}

.swing:nth-of-type(4) {
  animation-delay: 2s;
}
/*# sourceMappingURL=index.64977317.css.map */
